@import "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap";
@keyframes euiAnimFadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes euiGrow {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes focusRingAnimate {
  0% {
    box-shadow: 0 0 0 2px #07c0;
  }

  to {
    box-shadow: 0 0 0 2px #0071c2;
  }
}

@keyframes focusRingAnimateLarge {
  0% {
    box-shadow: 0 0 0 10px #07c0;
  }

  to {
    box-shadow: 0 0 0 4px #0071c2;
  }
}

@keyframes euiButtonActive {
  50% {
    transform: translateY(1px);
  }
}

.euiDataGrid {
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.euiDataGrid--fullScreen {
  z-index: 999;
  background: #fff;
  height: 100%;
  position: fixed;
  inset: 0;
}

.euiDataGrid--fullScreen .euiDataGrid__pagination {
  background: #f5f7fa;
  padding-bottom: 4px;
  box-shadow: 1px 0 0 1px #d3dae6;
}

.euiDataGrid__content {
  z-index: 1;
  font-feature-settings: "tnum" 1;
  background: #fafbfd;
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.euiDataGrid__customRenderBody {
  scrollbar-color: #69707d80 #fff;
  scrollbar-width: thin;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.euiDataGrid__customRenderBody::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.euiDataGrid__customRenderBody::-webkit-scrollbar-thumb {
  background-color: #69707d80;
  background-clip: content-box;
  border: 6px solid #fff;
  border-radius: 16px;
}

.euiDataGrid__customRenderBody::-webkit-scrollbar-corner {
  background-color: #fff;
}

.euiDataGrid__customRenderBody::-webkit-scrollbar-track {
  background-color: #fff;
}

.euiDataGrid__pagination {
  z-index: 2;
  flex-grow: 0;
  padding-top: 4px;
}

.euiDataGrid__restrictBody {
  height: 100vh;
  overflow: hidden;
}

.euiDataGrid__restrictBody .euiHeader[data-fixed-header] {
  z-index: 998 !important;
}

.euiDataGrid__restrictBody .euiOverlayMask[data-relative-to-header="below"] {
  top: 0;
}

.euiDataGrid__restrictBody .euiFlyout {
  height: 100%;
  top: 0;
}

.euiDataGrid__focusWrap {
  height: 100%;
}

.euiDataGrid__virtualized {
  scrollbar-color: #69707d80 #fff;
  scrollbar-width: thin;
  scroll-padding: 0;
}

.euiDataGrid__virtualized::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.euiDataGrid__virtualized::-webkit-scrollbar-thumb {
  background-color: #69707d80;
  background-clip: content-box;
  border: 6px solid #fff;
  border-radius: 16px;
}

.euiDataGrid__virtualized::-webkit-scrollbar-corner {
  background-color: #fff;
}

.euiDataGrid__virtualized::-webkit-scrollbar-track {
  background-color: #fff;
}

.euiDataGrid__scrollOverlay {
  pointer-events: none;
  position: absolute;
  inset: -1px 0 0;
  box-shadow: inset 0 0 0 1px #d3dae6;
}

.euiDataGrid--bordersHorizontal .euiDataGrid__scrollOverlay {
  box-shadow: inset 0 -2px 0 -1px #d3dae6;
}

.euiDataGrid__scrollOverlay .euiDataGrid__scrollBarOverlayBottom {
  background-color: #d3dae6;
  width: 100%;
  height: 1px;
  position: absolute;
}

.euiDataGrid__scrollOverlay .euiDataGrid__scrollBarOverlayRight {
  background-color: #d3dae6;
  width: 1px;
  height: 100%;
  position: absolute;
}

.euiDataGridHeader {
  z-index: 999;
  background: #fff;
  width: fit-content;
  display: flex;
  position: sticky;
  top: 0;
}

.euiDataGridHeaderCell {
  flex: none;
  align-items: center;
  padding: 6px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.71429rem;
  display: flex;
  position: relative;
}

.euiDataGridHeaderCell > [data-focus-lock-disabled] {
  width: 100%;
}

.euiDataGridHeaderCell--hasColumnActions:focus-within, .euiDataGridHeaderCell--isActionsPopoverOpen, .euiDataGridHeaderCell:focus {
  outline: none;
}

.euiDataGridHeaderCell--hasColumnActions:focus-within:after, .euiDataGridHeaderCell--isActionsPopoverOpen:after, .euiDataGridHeaderCell:focus:after {
  content: "";
  border: 2px solid var(--euiDataGridCellOutlineColor, #0071c2);
  z-index: 2;
  pointer-events: none;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.euiDataGridHeaderCell .euiDataGridHeaderCell__content {
  flex-grow: 1;
}

.euiDataGridHeaderCell:not(.euiDataGridHeaderCell--controlColumn) .euiDataGridHeaderCell__button {
  outline: none;
  align-items: center;
  gap: 4px;
  width: 100%;
  font-weight: 700;
  display: flex;
  position: relative;
}

.euiDataGridHeaderCell:not(.euiDataGridHeaderCell--controlColumn) .euiDataGridHeaderCell__content {
  text-align: left;
  max-width: 100%;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.euiDataGridHeaderCell:not(.euiDataGridHeaderCell--controlColumn) .euiDataGridHeaderCell__sortingArrow {
  flex: none;
}

.euiDataGridHeaderCell:not(.euiDataGridHeaderCell--controlColumn) .euiDataGridHeaderCell__icon {
  opacity: 0;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 16px;
  margin-left: auto;
  transition: width .15s ease-in, opacity .35s ease-in;
  display: flex;
  overflow: hidden;
}

.euiDataGridHeaderCell:not(.euiDataGridHeaderCell--controlColumn) .euiPopover-isOpen .euiDataGridHeaderCell__icon, .euiDataGridHeaderCell:not(.euiDataGridHeaderCell--controlColumn):focus-within .euiDataGridHeaderCell__icon, .euiDataGridHeaderCell:not(.euiDataGridHeaderCell--controlColumn):hover .euiDataGridHeaderCell__icon {
  opacity: 1;
  width: 16px;
}

.euiDataGridHeaderCell.euiDataGridHeaderCell--currency .euiDataGridHeaderCell__content, .euiDataGridHeaderCell.euiDataGridHeaderCell--numeric .euiDataGridHeaderCell__content {
  text-align: right;
}

.euiDataGridHeader__action--selected {
  font-weight: 700 !important;
}

.euiDataGrid--bordersNone.euiDataGrid--bordersHorizontal .euiDataGridHeader {
  background: #fff;
}

.euiDataGrid--headerUnderline .euiDataGridHeaderCell {
  border: none;
  border-bottom: 2px solid #343741;
}

.euiDataGrid--bordersNone.euiDataGrid--headerUnderline .euiDataGridHeaderCell {
  border-color: #343741;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.euiDataGrid--headerShade .euiDataGridHeaderCell {
  background: #f5f7fa;
}

.euiDataGrid--headerShade.euiDataGrid--bordersAll .euiDataGridHeaderCell {
  border-bottom: 1px solid #d3dae6;
  border-left: none;
  border-right: 1px solid #d3dae6;
}

.euiDataGrid--headerShade.euiDataGrid--bordersAll .euiDataGridHeaderCell:first-of-type {
  border-left: 1px solid #d3dae6;
}

.euiDataGrid--headerShade.euiDataGrid--bordersHorizontal .euiDataGridHeaderCell {
  border-top: none;
  border-bottom: 1px solid #d3dae6;
}

.euiDataGrid--bordersNone .euiDataGridHeaderCell {
  border: none;
}

.euiDataGrid--borderhorizontal .euiDataGridHeaderCell {
  border-top: none;
  border-left: none;
  border-right: none;
}

.euiDataGrid--fontSizeSmall .euiDataGridHeaderCell {
  font-size: .857143rem;
  line-height: 1.14286rem;
}

.euiDataGrid--fontSizeLarge .euiDataGridHeaderCell {
  font-size: 1rem;
  line-height: 1.71429rem;
}

.euiDataGrid--paddingSmall .euiDataGridHeaderCell {
  padding: 4px;
}

.euiDataGrid--paddingLarge .euiDataGridHeaderCell {
  padding: 8px;
}

.euiDataGrid--noControls.euiDataGrid--bordersAll .euiDataGridHeaderCell, .euiDataGrid--noControls.euiDataGrid--bordersHorizontal .euiDataGridHeaderCell {
  border-top: 1px solid #d3dae6;
}

.euiDataGridFooter {
  width: fit-content;
  display: flex;
}

.euiDataGridRowCell.euiDataGridFooterCell {
  flex: none;
  font-weight: 700;
  position: relative;
}

.euiDataGrid--stickyFooter .euiDataGridFooter {
  position: sticky;
  bottom: 0;
}

.euiDataGrid--footerOverline .euiDataGridRowCell.euiDataGridFooterCell {
  border-top: 2px solid #d3dae6;
  background: #fff !important;
  border-top-color: #343741 !important;
}

.euiDataGrid--bordersHorizontal .euiDataGridRowCell.euiDataGridFooterCell, .euiDataGrid--bordersNone .euiDataGridRowCell.euiDataGridFooterCell {
  border-left: none;
  border-right: none;
}

.euiDataGrid--footerShade .euiDataGridRowCell.euiDataGridFooterCell {
  background: #f5f7fa;
}

.euiDataGridColumnResizer {
  cursor: ew-resize;
  opacity: 0;
  z-index: 2;
  width: 16px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -8px;
}

.euiDataGridColumnResizer:after {
  content: "";
  background-color: #07c;
  width: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 7px;
}

.euiDataGridColumnResizer:active, .euiDataGridColumnResizer:hover {
  opacity: 1;
}

.euiDataGridColumnResizer:active ~ .euiDataGridHeaderCell__content, .euiDataGridColumnResizer:hover ~ .euiDataGridHeaderCell__content {
  -webkit-user-select: none;
  user-select: none;
}

.euiDataGridHeaderCell:last-child .euiDataGridColumnResizer {
  width: 8px;
  right: 0;
}

.euiDataGridHeaderCell:last-child .euiDataGridColumnResizer:after {
  left: auto;
  right: 0;
}

.euiDataGridRow {
  background-color: #fff;
}

.euiDataGridRowCell {
  --euiDataGridCellOutlineColor: #07c;
  border-bottom: 1px solid #d3dae6;
  border-right: 1px solid #edf0f5;
  position: relative;
}

.euiDataGridRowCell .euiDataGridRowCell__content {
  height: 100%;
  padding: 6px;
  font-size: 1rem;
  line-height: 1.71429rem;
  overflow: hidden;
}

.euiDataGridRowCell .euiDataGridRowCell__content--autoHeight {
  height: auto;
}

.euiDataGridRowCell .euiDataGridRowCell__content--lineCountHeight {
  border-bottom: 6px solid #0000;
  padding-bottom: 0;
}

.euiDataGridRowCell > [data-focus-lock-disabled] {
  height: 100%;
}

.euiDataGridRowCell.euiDataGridRowCell--firstColumn {
  border-left: 1px solid #d3dae6;
}

.euiDataGridRowCell.euiDataGridRowCell--lastColumn {
  border-right-color: #d3dae6;
}

.euiDataGridRowCell.euiDataGridRowCell--open, .euiDataGridRowCell:focus, .euiDataGridRowCell:hover {
  outline: none;
}

.euiDataGridRowCell.euiDataGridRowCell--open:after, .euiDataGridRowCell:focus:after, .euiDataGridRowCell:hover:after {
  content: "";
  border: 2px solid var(--euiDataGridCellOutlineColor, #0071c2);
  z-index: 2;
  pointer-events: none;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.euiDataGridRowCell:hover:not(:focus, :focus-within, .euiDataGridRowCell--open) {
  --euiDataGridCellOutlineColor: #69707d;
}

.euiDataGridRowCell:hover:not(:focus, :focus-within, .euiDataGridRowCell--open) .euiDataGridRowCell__actions {
  animation-delay: .35s;
}

.euiDataGridRowCell.euiDataGridRowCell--open .euiDataGridRowCell__actions, .euiDataGridRowCell:focus-within .euiDataGridRowCell__actions, .euiDataGridRowCell:focus .euiDataGridRowCell__actions, .euiDataGridRowCell:hover .euiDataGridRowCell__actions, .euiDataGridRowCell[data-keyboard-closing] .euiDataGridRowCell__actions {
  animation-name: euiDataGridCellActionsSlideIn;
  animation-duration: 90ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.euiDataGridRowCell:not(:hover):not(:focus):not(.euiDataGridRowCell--open):not([data-keyboard-closing]) .euiDataGridRowCell__actions {
  display: none;
}

.euiDataGridRowCell.euiDataGridRowCell--currency, .euiDataGridRowCell.euiDataGridRowCell--numeric {
  text-align: right;
}

.euiDataGridRowCell.euiDataGridRowCell--uppercase {
  text-transform: uppercase;
}

.euiDataGridRowCell.euiDataGridRowCell--lowercase {
  text-transform: lowercase;
}

.euiDataGridRowCell.euiDataGridRowCell--capitalize {
  text-transform: capitalize;
}

.euiDataGridRowCell__popover {
  scrollbar-color: #69707d80 transparent;
  scrollbar-width: thin;
  filter: none;
  animation-name: euiDataGridCellPopover;
  animation-duration: .25s;
  overflow: auto;
  box-shadow: 0 1px 5px #0000001a, 0 3.6px 13px #00000012, 0 8.4px 23px #0000000f, 0 23px 35px #0000000d;
  z-index: 1000 !important;
}

.euiDataGridRowCell__popover::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.euiDataGridRowCell__popover::-webkit-scrollbar-thumb {
  background-color: #69707d80;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 16px;
}

.euiDataGridRowCell__popover::-webkit-scrollbar-corner {
  background-color: #0000;
}

.euiDataGridRowCell__popover::-webkit-scrollbar-track {
  background-color: #0000;
}

.euiDataGridRowCell--controlColumn .euiDataGridRowCell__content {
  align-items: center;
  height: auto;
  max-height: 100%;
  display: flex;
}

.euiDataGridRowCell--controlColumn .euiDataGridRowCell__content.euiDataGridRowCell__content--defaultHeight {
  height: 100%;
}

.euiDataGridRowCell__actions, .euiDataGridRowCell__actions + [data-euiportal] > .euiPopover {
  position: absolute;
  bottom: 100%;
}

.euiDataGridRowCell--alignLeft .euiDataGridRowCell__actions, .euiDataGridRowCell--alignLeft .euiDataGridRowCell__actions + [data-euiportal] > .euiPopover {
  left: 0;
}

.euiDataGridRowCell--alignRight .euiDataGridRowCell__actions, .euiDataGridRowCell--alignRight .euiDataGridRowCell__actions + [data-euiportal] > .euiPopover {
  right: 0;
}

.euiDataGridRowCell__actions {
  z-index: 998;
  background-color: var(--euiDataGridCellOutlineColor);
  color: #fff;
  border: 1px solid var(--euiDataGridCellOutlineColor);
  transform-origin: bottom;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  gap: 2px;
  margin-bottom: -1px;
  padding-inline: 2px;
  display: flex;
  transform: scaleY(0);
}

.euiDataGridRowCell[data-gridcell-visible-row-index="0"] > .euiDataGridRowCell__actions {
  z-index: 999;
}

.euiDataGridRowCell--alignLeft .euiDataGridRowCell__actions {
  border-bottom-right-radius: 3px;
}

.euiDataGridRowCell--alignRight .euiDataGridRowCell__actions {
  border-bottom-left-radius: 3px;
}

.euiDataGridRowCell__actions:after {
  content: "";
  background-color: var(--euiDataGridCellOutlineColor);
  width: 2px;
  height: 2px;
  position: absolute;
  top: 100%;
}

.euiDataGridRowCell--alignLeft .euiDataGridRowCell__actions:after {
  left: -1px;
}

.euiDataGridRowCell--alignRight .euiDataGridRowCell__actions:after {
  right: -1px;
}

.euiDataGridRowCell__actionButtonIcon {
  border-radius: 0;
  width: 16px;
  height: 20px;
}

.euiDataGridRowCell__actionButtonIcon, .euiDataGridRowCell__actionButtonIcon svg {
  color: currentColor !important;
  fill: currentColor !important;
  background-color: #0000 !important;
}

.euiDataGridRowCell__actionButtonIcon.euiDataGridRowCell__expandCell .euiIcon {
  width: 120%;
  height: 100%;
}

.euiDataGrid--stripes .euiDataGridRow--striped {
  background-color: #f5f7fa;
}

.euiDataGrid--rowHoverHighlight .euiDataGridRow:hover {
  background-color: #fff9e8;
}

.euiDataGrid--bordersNone .euiDataGridRowCell {
  border-color: #0000 !important;
}

.euiDataGrid--bordersHorizontal .euiDataGridRowCell {
  border-left-color: #0000;
  border-right-color: #0000;
}

.euiDataGrid--fontSizeSmall .euiDataGridRowCell .euiDataGridRowCell__content {
  font-size: .857143rem;
  line-height: 1.14286rem;
}

.euiDataGrid--fontSizeLarge .euiDataGridRowCell .euiDataGridRowCell__content {
  font-size: 1rem;
  line-height: 1.71429rem;
}

.euiDataGrid--paddingSmall .euiDataGridRowCell .euiDataGridRowCell__content {
  padding: 4px;
}

.euiDataGrid--paddingSmall .euiDataGridRowCell .euiDataGridRowCell__content--lineCountHeight {
  border-bottom: 4px solid #0000;
  padding-bottom: 0;
}

.euiDataGrid--paddingLarge .euiDataGridRowCell .euiDataGridRowCell__content {
  padding: 8px;
}

.euiDataGrid--paddingLarge .euiDataGridRowCell .euiDataGridRowCell__content--lineCountHeight {
  border-bottom: 8px solid #0000;
  padding-bottom: 0;
}

@keyframes euiDataGridCellActionsSlideIn {
  0% {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
}

@keyframes euiDataGridCellPopover {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.euiDataGrid__controls {
  z-index: 2;
  background: #fafbfd;
  border: 1px solid #d3dae6;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px 4px 0;
  display: flex;
  position: relative;
}

.euiDataGrid__rightControls {
  white-space: nowrap;
}

.euiDataGrid__rightControls:only-child {
  margin-left: auto;
}

.euiDataGrid__rightControls > * + * {
  margin-left: 8px;
}

.euiDataGrid__leftControls > * + * {
  margin-left: 2px;
}

.euiDataGrid--bordersNone .euiDataGrid__controls {
  background: #fff;
  border: none;
}

.euiDataGrid--bordersHorizontal .euiDataGrid__controls {
  background: #fff;
  border-top: none;
  border-left: none;
  border-right: none;
}

.euiDataGrid__controlScroll {
  scrollbar-color: #69707d80 transparent;
  scrollbar-width: thin;
  height: 100%;
  max-height: 400px;
  margin: -8px;
  padding: 8px;
  overflow: hidden auto;
  transform: translateZ(0);
  -webkit-mask-image: linear-gradient(#ff00001a 0, red 7.5px calc(100% - 7.5px), #ff00001a);
  mask-image: linear-gradient(#ff00001a 0, red 7.5px calc(100% - 7.5px), #ff00001a);
}

.euiDataGrid__controlScroll::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.euiDataGrid__controlScroll::-webkit-scrollbar-thumb {
  background-color: #69707d80;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 16px;
}

.euiDataGrid__controlScroll::-webkit-scrollbar-corner {
  background-color: #0000;
}

.euiDataGrid__controlScroll::-webkit-scrollbar-track {
  background-color: #0000;
}

.euiDataGrid__controlScroll:focus {
  outline: none;
}

.euiDataGrid__controlScroll[tabindex="0"]:focus:focus-visible {
  outline-style: auto;
}

.euiDataGridColumnSelector__item {
  padding: 4px;
}

.euiDataGridColumnSelector__item-isDragging {
  background: #fff;
  box-shadow: 0 1px 5px #0000001a, 0 3.6px 13px #00000012, 0 8.4px 23px #0000000f, 0 23px 35px #0000000d;
}

.euiDataGridColumnSelector__columnList {
  scrollbar-color: #69707d80 transparent;
  scrollbar-width: thin;
  height: 100%;
  max-height: 400px;
  margin: 0 -8px;
  overflow: hidden auto;
  transform: translateZ(0);
  -webkit-mask-image: linear-gradient(#ff00001a 0, red 7.5px calc(100% - 7.5px), #ff00001a);
  mask-image: linear-gradient(#ff00001a 0, red 7.5px calc(100% - 7.5px), #ff00001a);
}

.euiDataGridColumnSelector__columnList::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.euiDataGridColumnSelector__columnList::-webkit-scrollbar-thumb {
  background-color: #69707d80;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 16px;
}

.euiDataGridColumnSelector__columnList::-webkit-scrollbar-corner {
  background-color: #0000;
}

.euiDataGridColumnSelector__columnList::-webkit-scrollbar-track {
  background-color: #0000;
}

.euiDataGridColumnSelector__columnList:focus {
  outline: none;
}

.euiDataGridColumnSelector__columnList[tabindex="0"]:focus:focus-visible {
  outline-style: auto;
}

.euiDataGridColumnSelector__itemLabel {
  font-size: .857143rem;
  line-height: 1.14286rem;
}

.euiDataGridColumnSorting__item-isDragging {
  background: #fff;
  box-shadow: 0 1px 5px #0000001a, 0 3.6px 13px #00000012, 0 8.4px 23px #0000000f, 0 23px 35px #0000000d;
}

.euiDataGridColumnSorting__fieldList {
  scrollbar-color: #69707d80 transparent;
  scrollbar-width: thin;
  height: 100%;
  max-height: 300px;
  padding-top: 4px;
  padding-bottom: 4px;
  overflow: hidden auto;
  transform: translateZ(0);
  -webkit-mask-image: linear-gradient(#ff00001a 0, red 7.5px calc(100% - 7.5px), #ff00001a);
  mask-image: linear-gradient(#ff00001a 0, red 7.5px calc(100% - 7.5px), #ff00001a);
}

.euiDataGridColumnSorting__fieldList::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.euiDataGridColumnSorting__fieldList::-webkit-scrollbar-thumb {
  background-color: #69707d80;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 16px;
}

.euiDataGridColumnSorting__fieldList::-webkit-scrollbar-corner {
  background-color: #0000;
}

.euiDataGridColumnSorting__fieldList::-webkit-scrollbar-track {
  background-color: #0000;
}

.euiDataGridColumnSorting__fieldList:focus {
  outline: none;
}

.euiDataGridColumnSorting__fieldList[tabindex="0"]:focus:focus-visible {
  outline-style: auto;
}

.euiDataGridColumnSorting__field {
  outline-offset: -2px;
  width: 100%;
  padding: 4px 8px;
  display: block;
}

.euiDataGridColumnSorting__field:focus, .euiDataGridColumnSorting__field:hover {
  cursor: pointer;
  text-decoration: underline;
}

.euiDataGridColumnSorting__field:focus {
  background-color: #0077cc1a;
}

.euiDataGridColumnSorting__field:disabled {
  cursor: not-allowed;
  color: #abb4c4;
  text-decoration: none;
}

.euiDataGridColumnSorting__name {
  padding-right: 24px;
}

@media only screen and (width <= 574px) {
  .euiDataGridColumnSorting__name {
    padding-right: 4px;
  }
}

@media only screen and (width >= 575px) and (width <= 767px) {
  .euiDataGridColumnSorting__name {
    padding-right: 4px;
  }
}

.euiDataGridColumnSorting__order {
  min-width: 200px;
}

@media only screen and (width <= 574px) {
  .euiDataGridColumnSorting__order {
    min-width: unset;
  }
}

@media only screen and (width >= 575px) and (width <= 767px) {
  .euiDataGridColumnSorting__order {
    min-width: unset;
  }
}

.euiDataGridColumnSorting__order .euiButtonGroup__buttons {
  border: none;
}

.euiDataGridColumnSorting__order .euiButtonGroupButton {
  font-size: 12px;
}

.euiDataGrid__displayPopoverPanel {
  width: 416px;
}

.euiDataGrid__keyboardShortcuts {
  overflow-block: auto;
  max-block-size: 80vh;
  max-inline-size: 400px;
  display: block;
  overflow-y: auto;
}

.euiDataGrid__keyboardShortcuts .euiDescriptionList {
  row-gap: 0;
}

.euiCheckbox {
  position: relative;
}

.euiCheckbox .euiCheckbox__input {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 3px;
}

.euiCheckbox .euiCheckbox__input ~ .euiCheckbox__label {
  z-index: 2;
  cursor: pointer;
  padding-left: 24px;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  position: relative;
}

.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
  background: #fff 50% no-repeat;
  border: 1px solid #939496;
  border-radius: 4px;
  padding: 7px;
  transition: background-color .15s ease-in, border-color .15s ease-in;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
}

.euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
  background-color: #07c;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8'%3E%3Cpath d='M.875 3.625l3 3m0 0l5.25-5.25' fill='none' stroke='%23FFF' stroke-linecap='round' stroke-width='1.5'/%3E%3C/svg%3E");
  border-color: #07c;
}

.euiCheckbox .euiCheckbox__input:indeterminate + .euiCheckbox__square {
  background-color: #07c;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6'%3E%3Cpath fill='%23FFF' fill-rule='evenodd' d='M0 0h6v6H0z'/%3E%3C/svg%3E");
  border-color: #07c;
}

.euiCheckbox .euiCheckbox__input:focus + .euiCheckbox__square {
  border-color: #07c;
  outline: 2px solid;
}

.euiCheckbox .euiCheckbox__input:focus + .euiCheckbox__square:focus-visible {
  outline-style: auto;
}

.euiCheckbox .euiCheckbox__input:focus + .euiCheckbox__square:not(:focus-visible) {
  outline: none;
}

.euiCheckbox .euiCheckbox__input[readonly], .euiCheckbox .euiCheckbox__input[readonly] ~ .euiCheckbox__label {
  cursor: default !important;
}

.euiCheckbox .euiCheckbox__input[readonly]:focus + .euiCheckbox__square {
  border-color: #939496;
  outline-color: #939496 !important;
}

.euiCheckbox .euiCheckbox__input[disabled] {
  cursor: not-allowed !important;
}

.euiCheckbox .euiCheckbox__input[disabled] ~ .euiCheckbox__label {
  color: #98a2b3;
  cursor: not-allowed !important;
}

.euiCheckbox .euiCheckbox__input[disabled] + .euiCheckbox__square {
  box-shadow: none;
  background-color: #d3dae6;
  border-color: #d3dae6;
}

.euiCheckbox .euiCheckbox__input:checked[disabled] + .euiCheckbox__square {
  box-shadow: none;
  background-color: #d3dae6;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8'%3E%3Cpath d='M.875 3.625l3 3m0 0l5.25-5.25' fill='none' stroke='%235E646F' stroke-linecap='round' stroke-width='1.5'/%3E%3C/svg%3E");
  border-color: #d3dae6;
}

.euiCheckbox .euiCheckbox__input:indeterminate[disabled] + .euiCheckbox__square {
  box-shadow: none;
  background-color: #d3dae6;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6'%3E%3Cpath fill='%235E646F' fill-rule='evenodd' d='M0 0h6v6H0z'/%3E%3C/svg%3E");
  border-color: #d3dae6;
}

.euiCheckbox.euiCheckbox--noLabel {
  min-width: 16px;
  min-height: 16px;
}

.euiCheckbox.euiCheckbox--noLabel .euiCheckbox__input, .euiCheckbox.euiCheckbox--noLabel .euiCheckbox__square {
  top: 0;
}

.euiCheckbox.euiCheckbox--noLabel .euiCheckbox__input {
  margin: 0;
}

.euiDescribedFormGroup {
  max-width: 800px;
}

.euiDescribedFormGroup + * {
  margin-top: 24px;
}

.euiDescribedFormGroup.euiDescribedFormGroup--fullWidth {
  max-width: 100%;
}

.euiDescribedFormGroup .euiDescribedFormGroup__description {
  padding-top: 8px;
}

.euiDescribedFormGroup .euiDescribedFormGroup__fields {
  min-width: 0;
}

.euiDescribedFormGroup .euiDescribedFormGroup__fields > .euiFormRow--hasEmptyLabelSpace:first-child, .euiDescribedFormGroup .euiDescribedFormGroup__fields > .euiFormRow:first-child:not(.euiFormRow--hasLabel) {
  margin-top: 0;
}

.euiDescribedFormGroup .euiDescribedFormGroup__fields > .euiFormRow--hasEmptyLabelSpace:first-child:before, .euiDescribedFormGroup .euiDescribedFormGroup__fields > .euiFormRow:first-child:not(.euiFormRow--hasLabel):before {
  content: "";
}

.euiDescribedFormGroup__descriptionColumn {
  min-width: min(20rem, 50%);
}

.euiForm__error {
  font-size: 1rem;
  line-height: 1.71429rem;
  list-style: disc;
}

.euiForm__errors {
  margin-bottom: 16px;
}

.euiFormControlLayout {
  width: 100%;
  max-width: 400px;
  height: 40px;
}

.euiFormControlLayout--fullWidth {
  max-width: 100%;
}

.euiFormControlLayout--compressed {
  height: 32px;
}

.euiFormControlLayout--inGroup {
  height: 100%;
}

.euiFormControlLayout--1icons {
  --eui-form-control-layout-icons-padding: 34px;
  padding-right: 34px;
}

.euiFormControlLayout--1icons[class*="compressed"] {
  --eui-form-control-layout-icons-padding: 26px;
  padding-right: 26px;
}

.euiFormControlLayout--2icons {
  --eui-form-control-layout-icons-padding: 56px;
  padding-right: 56px;
}

.euiFormControlLayout--2icons[class*="compressed"] {
  --eui-form-control-layout-icons-padding: 44px;
  padding-right: 44px;
}

.euiFormControlLayout--3icons {
  --eui-form-control-layout-icons-padding: 78px;
  padding-right: 78px;
}

.euiFormControlLayout--3icons[class*="compressed"] {
  --eui-form-control-layout-icons-padding: 62px;
  padding-right: 62px;
}

.euiFormControlLayout--4icons {
  --eui-form-control-layout-icons-padding: 100px;
  padding-right: 100px;
}

.euiFormControlLayout--4icons[class*="compressed"] {
  --eui-form-control-layout-icons-padding: 80px;
  padding-right: 80px;
}

.euiFormControlLayout--5icons {
  --eui-form-control-layout-icons-padding: 122px;
  padding-right: 122px;
}

.euiFormControlLayout--5icons[class*="compressed"] {
  --eui-form-control-layout-icons-padding: 98px;
  padding-right: 98px;
}

.euiFormControlLayout__childrenWrapper {
  position: relative;
}

.euiFormControlLayout--group {
  background-color: #fbfcfd;
  background-repeat: no-repeat;
  background-size: 0 100%;
  align-items: stretch;
  padding: 1px;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  display: flex;
  box-shadow: 0 0 #0000, inset 0 0 0 1px #112a861a;
}

@supports ((-moz-appearance: none)) {
  .euiFormControlLayout--group {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiFormControlLayout--group .euiButtonEmpty, .euiFormControlLayout--group .euiButtonIcon, .euiFormControlLayout--group .euiFormLabel, .euiFormControlLayout--group .euiText, .euiFormControlLayout--group > * {
  height: 100%;
}

.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper {
  flex-grow: 1;
  overflow: hidden;
}

.euiFormControlLayout--group .euiFormControlLayout__append, .euiFormControlLayout--group .euiFormControlLayout__prepend {
  border-radius: 0;
  flex-shrink: 0;
  max-width: 100%;
  height: 100%;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.euiFormControlLayout--group .euiFormControlLayout__append.euiIcon, .euiFormControlLayout--group .euiFormControlLayout__append .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__prepend.euiIcon, .euiFormControlLayout--group .euiFormControlLayout__prepend .euiIcon {
  background-color: #e9edf3;
  border-radius: 0;
  width: 32px;
  padding: 0 8px;
}

.euiFormControlLayout--group .euiFormControlLayout__append.euiButtonEmpty, .euiFormControlLayout--group .euiFormControlLayout__append .euiButtonEmpty, .euiFormControlLayout--group .euiFormControlLayout__append.euiButtonIcon, .euiFormControlLayout--group .euiFormControlLayout__append .euiButtonIcon, .euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonEmpty, .euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonEmpty, .euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonIcon, .euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonIcon {
  transform: none !important;
}

.euiFormControlLayout--group .euiFormControlLayout__append.euiButtonEmpty .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__append .euiButtonEmpty .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__append.euiButtonIcon .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__append .euiButtonIcon .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonEmpty .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonEmpty .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonIcon .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonIcon .euiIcon {
  width: 16px;
  padding: 0;
  background: none !important;
}

.euiFormControlLayout--group .euiButtonIcon {
  border-radius: 0;
  width: 32px;
  padding: 0 8px;
}

.euiFormControlLayout--group .euiButtonIcon:not(:focus) {
  background-color: #e9edf3;
}

.euiFormControlLayout--group .euiButtonIcon:focus-visible {
  outline-offset: -2px;
  outline: 2px solid #0071c2;
}

.euiFormControlLayout--group .euiToolTipAnchor > .euiIcon {
  background-color: #e9edf3;
  border-radius: 0;
  width: 32px;
  height: 100%;
  padding: 0 8px;
}

.euiFormControlLayout--group > .euiFormControlLayout__append, .euiFormControlLayout--group > .euiFormControlLayout__prepend {
  max-width: 50%;
}

.euiFormControlLayout--group .euiFormLabel, .euiFormControlLayout--group .euiText:not(.euiFormControlLayoutDelimited__delimiter) {
  background-color: #e9edf3;
  padding: 12px;
  cursor: default !important;
  line-height: 16px !important;
}

.euiFormControlLayout--group .euiFormLabel + :not(.euiFormControlLayout__childrenWrapper):not(input), .euiFormControlLayout--group .euiText:not(.euiFormControlLayoutDelimited__delimiter) + :not(.euiFormControlLayout__childrenWrapper):not(input), .euiFormControlLayout--group > :not(.euiFormControlLayout__childrenWrapper) + .euiFormLabel, .euiFormControlLayout--group > :not(.euiFormControlLayout__childrenWrapper) + .euiText {
  margin-left: -12px;
}

.euiFormControlLayout--group .euiButtonEmpty {
  border-right: none;
}

.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper ~ * .euiButtonEmpty, .euiFormControlLayout--group .euiFormControlLayout__childrenWrapper ~ .euiButtonEmpty {
  border-left: none;
  border-right: none;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed {
  background-color: #fbfcfd;
  background-repeat: no-repeat;
  background-size: 0 100%;
  border-radius: 3px;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px #112a861a;
}

@supports ((-moz-appearance: none)) {
  .euiFormControlLayout--group.euiFormControlLayout--compressed {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormLabel, .euiFormControlLayout--group.euiFormControlLayout--compressed .euiText:not(.euiFormControlLayoutDelimited__delimiter) {
  padding: 8px;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormLabel + :not(.euiFormControlLayout__childrenWrapper), .euiFormControlLayout--group.euiFormControlLayout--compressed .euiText:not(.euiFormControlLayoutDelimited__delimiter) + :not(.euiFormControlLayout__childrenWrapper), .euiFormControlLayout--group.euiFormControlLayout--compressed > :not(.euiFormControlLayout__childrenWrapper) + .euiFormLabel, .euiFormControlLayout--group.euiFormControlLayout--compressed > :not(.euiFormControlLayout__childrenWrapper) + .euiText {
  margin-left: -8px;
}

.euiFormControlLayout--group.euiFormControlLayout--readOnly {
  cursor: default;
  background: #e9edf3;
  border-color: #0000;
  box-shadow: inset 0 0 0 1px #e9edf3;
}

.euiFormControlLayout--group.euiFormControlLayout--readOnly input {
  background-color: #fff;
}

.euiFormControlLayoutDelimited {
  background-color: #fbfcfd;
  background-repeat: no-repeat;
  background-size: 0 100%;
  align-items: stretch;
  padding: 1px;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  display: flex;
  box-shadow: 0 0 #0000, inset 0 0 0 1px #112a861a;
}

@supports ((-moz-appearance: none)) {
  .euiFormControlLayoutDelimited {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiFormControlLayoutDelimited > .euiFormControlLayout__childrenWrapper {
  background-color: #fbfcfd;
  align-items: center;
  width: 100%;
  display: flex;
}

.euiFormControlLayoutDelimited[class*="--compressed"] {
  background-color: #fbfcfd;
  background-repeat: no-repeat;
  background-size: 0 100%;
  border-radius: 3px;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  box-shadow: inset 0 0 0 1px #112a861a;
}

@supports ((-moz-appearance: none)) {
  .euiFormControlLayoutDelimited[class*="--compressed"] {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiFormControlLayoutDelimited[class*="--fullWidth"] .euiFormControlLayout__childrenWrapper, .euiFormControlLayoutDelimited[class*="--fullWidth"] .euiFormControlLayout__childrenWrapper > :not(.euiFormControlLayoutDelimited__delimiter):not(.euiFormControlLayoutIcons) {
  width: 100%;
  max-width: none;
}

.euiFormControlLayoutDelimited[class*="-isDisabled"] {
  color: #98a2b3;
  -webkit-text-fill-color: #98a2b3;
  cursor: not-allowed;
  background: #eef2f7;
  box-shadow: inset 0 0 0 1px #112a861a;
}

.euiFormControlLayoutDelimited[class*="-isDisabled"]::placeholder {
  color: #98a2b3;
  opacity: 1;
}

.euiFormControlLayoutDelimited[class*="-isDisabled"] .euiFormControlLayout__childrenWrapper {
  background-color: #eef2f7;
}

.euiFormControlLayoutDelimited[class*="--readOnly"] {
  cursor: default;
  color: #343741;
  -webkit-text-fill-color: #343741;
  background: #fff;
  border-color: #0000;
  box-shadow: inset 0 0 0 1px #112a861a;
}

.euiFormControlLayoutDelimited[class*="--readOnly"] .euiFormControlLayout__childrenWrapper {
  background-color: #fff;
}

.euiFormControlLayoutDelimited--isInvalid .euiFormControlLayout__childrenWrapper {
  background-image: linear-gradient(0deg, #bd271e, #bd271e 2px, #0000 0, #0000);
  background-size: 100%;
}

.euiFormControlLayoutDelimited__input {
  text-align: center;
  min-width: 0;
  height: 100%;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.euiFormControlLayoutDelimited__delimiter {
  flex-grow: 0;
  align-self: stretch;
  line-height: 1;
}

.euiFormControlLayoutDelimited__delimiter, .euiFormControlLayoutIcons {
  align-items: center;
  display: flex;
}

.euiFormControlLayoutIcons {
  pointer-events: none;
}

.euiFormControlLayoutIcons > * + * {
  margin-left: 6px;
}

.euiFormControlLayoutIcons--absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
}

.euiFormControlLayout--compressed .euiFormControlLayoutIcons--absolute {
  left: 8px;
}

.euiFormControlLayoutIcons--static {
  flex-grow: 0;
  align-self: stretch;
  height: 100%;
  padding-inline: 12px;
  position: static;
}

.euiFormControlLayout--compressed .euiFormControlLayoutIcons--static {
  padding-inline: 8px;
}

.euiFormControlLayoutIcons--left {
  z-index: 1;
}

.euiFormControlLayoutIcons--right {
  left: auto;
  right: 12px;
}

.euiFormControlLayout--compressed .euiFormControlLayoutIcons--right {
  left: auto;
  right: 8px;
}

:disabled + .euiFormControlLayoutIcons {
  cursor: not-allowed;
  color: #98a2b3;
}

.euiFormControlLayoutClearButton {
  pointer-events: all;
  background-color: #98a2b3;
  border-radius: 16px;
  width: 16px;
  height: 16px;
  line-height: 0;
}

.euiFormControlLayoutClearButton:focus {
  outline: 2px solid;
}

.euiFormControlLayoutClearButton:focus:focus-visible {
  outline-style: auto;
}

.euiFormControlLayoutClearButton:focus:not(:focus-visible) {
  outline: none;
}

.euiFormControlLayoutClearButton .euiFormControlLayoutClearButton__icon {
  fill: #fff;
  stroke: #fff;
  stroke-width: 2px;
  width: 8px;
  height: 8px;
}

.euiFormControlLayoutClearButton--small {
  pointer-events: all;
  background-color: #98a2b3;
  border-radius: 12px;
  width: 12px;
  height: 12px;
  line-height: 0;
}

.euiFormControlLayoutClearButton--small:focus {
  outline: 2px solid;
}

.euiFormControlLayoutClearButton--small:focus:focus-visible {
  outline-style: auto;
}

.euiFormControlLayoutClearButton--small:focus:not(:focus-visible) {
  outline: none;
}

.euiFormControlLayoutClearButton--small .euiFormControlLayoutClearButton__icon {
  fill: #fff;
  stroke: #fff;
  stroke-width: 4px;
  width: 6px;
  height: 6px;
}

.euiFormControlLayoutCustomIcon {
  pointer-events: none;
  font-size: 0;
}

.euiFormControlLayoutCustomIcon--clickable {
  pointer-events: all;
  width: 16px;
  height: 16px;
}

.euiFormControlLayoutCustomIcon--clickable .euiFormControlLayoutCustomIcon__icon {
  vertical-align: baseline;
  transform: none;
}

.euiFormControlLayoutCustomIcon--clickable:focus {
  outline: 2px solid;
}

.euiFormControlLayoutCustomIcon--clickable:focus:focus-visible {
  outline-style: auto;
}

.euiFormControlLayoutCustomIcon--clickable:focus:not(:focus-visible) {
  outline: none;
}

.euiFormControlLayoutCustomIcon--clickable:disabled {
  cursor: not-allowed;
  color: #98a2b3;
}

.euiFormErrorText {
  color: #bd271e;
  padding-top: 4px;
}

.euiFormErrorText, .euiFormLegend {
  font-size: .857143rem;
  line-height: 1.14286rem;
}

.euiFormLegend {
  word-break: break-word;
  color: #1a1c21;
  font-weight: 600;
  overflow-wrap: break-word !important;
}

.euiFormLegend:not(.euiFormLegend-isHidden) {
  margin-bottom: 8px;
}

.euiFormLegend:not(.euiFormLegend-isHidden).euiFormLegend--compressed {
  margin-bottom: 4px;
}

.euiFormHelpText {
  color: #69707d;
  padding-top: 4px;
}

.euiFormHelpText, .euiFormLabel {
  font-size: .857143rem;
  line-height: 1.14286rem;
}

.euiFormLabel {
  word-break: break-word;
  color: #1a1c21;
  font-weight: 600;
  transition: all .15s cubic-bezier(.694, .0482, .335, 1);
  display: inline-block;
  overflow-wrap: break-word !important;
}

.euiFormLabel.euiFormLabel-isInvalid {
  color: #bd271e;
}

.euiFormLabel.euiFormLabel-isFocused {
  color: #07c;
}

.euiFormLabel[for] {
  cursor: pointer;
}

.euiFormLabel[for].euiFormLabel-isDisabled {
  cursor: default;
}

.euiFormRow {
  flex-direction: column;
  max-width: 400px;
  display: flex;
}

.euiFormRow + .euiButton, .euiFormRow + .euiFormRow {
  margin-top: 16px;
}

.euiFormRow--fullWidth {
  max-width: 100%;
}

.euiFormRow--hasEmptyLabelSpace {
  justify-content: center;
  min-height: 40px;
  margin-top: 20px;
  padding-bottom: 0;
}

.euiFormRow__labelWrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 4px;
  display: flex;
}

.euiFormRow--horizontal {
  flex-direction: row;
  align-items: stretch;
}

.euiFormRow--horizontal .euiFormRow__label {
  hyphens: auto;
}

.euiFormRow--horizontal .euiFormRow__labelWrapper {
  width: calc(33% - 8px);
  margin-bottom: 0;
  margin-right: 8px;
  line-height: 31px;
  display: block;
}

.euiFormRow--horizontal .euiFormRow__fieldWrapper {
  width: 67%;
}

.euiFormRow--horizontal + .euiFormRow--horizontal {
  margin-top: 8px;
}

.euiFormRow--horizontal + .euiFormRow--horizontal.euiFormRow--hasSwitch {
  margin-top: 12px;
}

.euiFormRow--horizontal.euiFormRow--hasSwitch .euiFormRow__labelWrapper {
  width: auto;
  min-width: calc(33% - 8px);
  line-height: 19px;
}

.euiFormRow--horizontal.euiFormRow--hasSwitch .euiFormRow__fieldWrapper {
  width: auto;
}

.euiFormRow--horizontal.euiFormRow--hasSwitch .euiFormRow__fieldWrapper .euiSwitch--compressed {
  margin-top: 2px;
}

.euiFormRow--horizontal.euiFormRow--hasSwitch + .euiFormRow--horizontal {
  margin-top: 12px;
}

.euiFormRow__fieldWrapperDisplayOnly {
  align-items: center;
  min-height: 40px;
  display: flex;
}

.euiFormRow--compressed.euiFormRow--hasEmptyLabelSpace, .euiFormRow--compressed .euiFormRow__fieldWrapperDisplayOnly {
  min-height: 32px;
}

.euiRadio {
  position: relative;
}

.euiRadio .euiRadio__input {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 3px;
}

.euiRadio .euiRadio__input ~ .euiRadio__label {
  z-index: 2;
  cursor: pointer;
  padding-left: 24px;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  position: relative;
}

.euiRadio .euiRadio__input + .euiRadio__circle {
  background: #fff 50% no-repeat;
  border: 1px solid #939496;
  border-radius: 14px;
  padding: 7px;
  transition: background-color .15s ease-in, border-color .15s ease-in;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
}

.euiRadio .euiRadio__input:checked + .euiRadio__circle {
  background-color: #07c;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6'%3E%3Ccircle cx='8' cy='11' r='3' fill='%23FFF' fill-rule='evenodd' transform='translate(-5 -8)'/%3E%3C/svg%3E");
  border-color: #07c;
}

.euiRadio .euiRadio__input[disabled] {
  cursor: not-allowed !important;
}

.euiRadio .euiRadio__input[disabled] ~ .euiRadio__label {
  color: #98a2b3;
  cursor: not-allowed !important;
}

.euiRadio .euiRadio__input[disabled] + .euiRadio__circle {
  box-shadow: none;
  background-color: #d3dae6;
  border-color: #d3dae6;
}

.euiRadio .euiRadio__input:checked[disabled] + .euiRadio__circle {
  box-shadow: none;
  background-color: #d3dae6;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6'%3E%3Ccircle cx='8' cy='11' r='3' fill='%235E646F' fill-rule='evenodd' transform='translate(-5 -8)'/%3E%3C/svg%3E");
  border-color: #d3dae6;
}

.euiRadio .euiRadio__input:focus + .euiRadio__circle {
  border-color: #07c;
  outline: 2px solid;
}

.euiRadio .euiRadio__input:focus + .euiRadio__circle:focus-visible {
  outline-style: auto;
}

.euiRadio .euiRadio__input:focus + .euiRadio__circle:not(:focus-visible) {
  outline: none;
}

.euiRadio.euiRadio--noLabel {
  min-width: 16px;
  min-height: 16px;
}

.euiRadio.euiRadio--noLabel .euiRadio__circle, .euiRadio.euiRadio--noLabel .euiRadio__input {
  top: 0;
}

.euiRadio.euiRadio--noLabel .euiRadio__input {
  margin: 0;
}

.euiSwitch {
  align-items: flex-start;
  min-height: 20px;
  display: inline-flex;
  position: relative;
}

.euiSwitch .euiSwitch__label {
  cursor: pointer;
  vertical-align: middle;
  padding-left: 8px;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
}

.euiSwitch .euiSwitch__button {
  flex-shrink: 0;
  line-height: 0;
}

.euiSwitch .euiSwitch__button:focus .euiSwitch__track {
  outline-offset: 2px;
  border-color: #07c;
  outline: 2px solid;
}

.euiSwitch .euiSwitch__button:focus .euiSwitch__track:focus-visible {
  outline-style: auto;
}

.euiSwitch .euiSwitch__button:focus .euiSwitch__track:not(:focus-visible) {
  outline: none;
}

.euiSwitch .euiSwitch__button[aria-checked="false"] .euiSwitch__body {
  background-color: #69707dbf;
}

.euiSwitch .euiSwitch__button[aria-checked="false"] .euiSwitch__thumb {
  left: 0;
}

.euiSwitch .euiSwitch__button[aria-checked="false"] .euiSwitch__icon {
  right: -8px;
}

.euiSwitch .euiSwitch__button[aria-checked="false"] .euiSwitch__icon.euiSwitch__icon--checked {
  left: -34px;
  right: auto;
}

.euiSwitch .euiSwitch__button:disabled:hover, .euiSwitch .euiSwitch__button:disabled ~ .euiSwitch__label:hover {
  cursor: not-allowed;
}

.euiSwitch .euiSwitch__button:disabled .euiSwitch__body {
  background-color: #d3dae680;
}

.euiSwitch .euiSwitch__button:disabled .euiSwitch__thumb {
  box-shadow: none;
  background-color: #0000;
  border-color: #69707d80;
}

.euiSwitch .euiSwitch__button:disabled .euiSwitch__icon {
  fill: #69707d;
}

.euiSwitch .euiSwitch__button:disabled + .euiSwitch__label {
  color: #98a2b3;
}

.euiSwitch .euiSwitch__body {
  pointer-events: none;
  vertical-align: middle;
  background-color: #07c;
  border-radius: 20px;
  width: 44px;
  height: 20px;
  display: inline-block;
  position: relative;
}

.euiSwitch .euiSwitch__thumb {
  background: #fff 50% no-repeat;
  border: 1px solid #939496;
  border-radius: 18px;
  padding: 9px;
  transition: border-color .25s cubic-bezier(.34, 1.61, .7, 1), background-color .25s cubic-bezier(.34, 1.61, .7, 1), left .25s cubic-bezier(.34, 1.61, .7, 1), transform .25s cubic-bezier(.34, 1.61, .7, 1), -webkit-transform .25s cubic-bezier(.34, 1.61, .7, 1);
  display: inline-block;
  position: absolute;
  left: 24px;
}

.euiSwitch .euiSwitch__track {
  border-radius: 20px;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.euiSwitch .euiSwitch__icon {
  fill: #fff;
  width: 42px;
  height: 16px;
  transition: left .25s cubic-bezier(.34, 1.61, .7, 1), right .25s cubic-bezier(.34, 1.61, .7, 1);
  position: absolute;
  top: 2px;
  bottom: 0;
  right: -34px;
}

.euiSwitch .euiSwitch__icon--checked {
  fill: #fff;
  left: -8px;
  right: auto;
}

.euiSwitch:hover .euiSwitch__button:not(:disabled) .euiSwitch__thumb {
  transform: scale(1.05);
}

.euiSwitch:hover .euiSwitch__button:active .euiSwitch__thumb {
  transform: scale(.95);
}

.euiSwitch.euiSwitch--compressed {
  min-height: 16px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__label {
  line-height: 16px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__body {
  border-radius: 16px;
  width: 28px;
  height: 16px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__thumb {
  background: #fff 50% no-repeat;
  border: 1px solid #939496;
  border-radius: 12px;
  padding: 6px;
  transition: border-color .25s cubic-bezier(.34, 1.61, .7, 1), background-color .25s cubic-bezier(.34, 1.61, .7, 1), left .25s cubic-bezier(.34, 1.61, .7, 1), transform .25s cubic-bezier(.34, 1.61, .7, 1), -webkit-transform .25s cubic-bezier(.34, 1.61, .7, 1);
  top: 1px;
  left: 13px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__track {
  border-radius: 16px;
}

.euiSwitch.euiSwitch--mini {
  min-height: 10px;
}

.euiSwitch.euiSwitch--mini .euiSwitch__label {
  font-size: 12px;
  line-height: 10px;
}

.euiSwitch.euiSwitch--mini .euiSwitch__body {
  border-radius: 10px;
  width: 22px;
  height: 10px;
}

.euiSwitch.euiSwitch--mini .euiSwitch__thumb {
  background: #fff 50% no-repeat;
  border: 1px solid #939496;
  border-radius: 6px;
  padding: 3px;
  transition: border-color .25s cubic-bezier(.34, 1.61, .7, 1), background-color .25s cubic-bezier(.34, 1.61, .7, 1), left .25s cubic-bezier(.34, 1.61, .7, 1), transform .25s cubic-bezier(.34, 1.61, .7, 1), -webkit-transform .25s cubic-bezier(.34, 1.61, .7, 1);
  top: 1px;
  left: 13px;
}

.euiSwitch.euiSwitch--mini .euiSwitch__track {
  border-radius: 10px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked="false"] .euiSwitch__thumb, .euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked="false"] .euiSwitch__thumb {
  left: 1px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked="false"] .euiSwitch__thumb, .euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked="true"]:disabled .euiSwitch__thumb, .euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked="false"] .euiSwitch__thumb, .euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked="true"]:disabled .euiSwitch__thumb {
  border-color: #69707d80;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked="true"] .euiSwitch__thumb, .euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked="true"] .euiSwitch__thumb {
  border-color: #07c;
}

.euiDataGrid--fontSizeLarge .euiDataGridRowCell {
  font-size: 1.14286rem;
  line-height: 1.71429rem;
}

.euiFormControlLayout--group {
  background-color: #e9edf3;
  border-radius: 6px;
}

.euiFormControlLayout--group .euiFormControlLayout__prepend:first-child, .euiFormControlLayout--group .euiFormControlLayout__prepend:first-child [class*="euiButton"] {
  border-radius: 5px 0 0 5px;
}

.euiFormControlLayout--group .euiFormControlLayout__append:last-child, .euiFormControlLayout--group .euiFormControlLayout__append:last-child [class*="euiButton"] {
  border-radius: 0 5px 5px 0;
}

.euiFormControlLayout--group [class*="euiButton"]:focus {
  outline-offset: -2px;
  outline: 2px solid;
}

.euiFormControlLayout--group [class*="euiButton"]:focus:focus-visible {
  outline-style: auto;
}

.euiFormControlLayout--group [class*="euiButton"]:focus:not(:focus-visible) {
  outline: none;
}

.euiFormControlLayout--group .euiToolTipAnchor > .euiIcon {
  border-radius: 0 5px 5px 0;
}

.euiFormControlLayout--group .euiToolTipAnchor:first-child [class*="euiButton"] {
  border-radius: 5px 0 0 5px;
}

.euiFormControlLayout--group .euiToolTipAnchor:last-child .euiText, .euiFormControlLayout--group .euiToolTipAnchor:last-child [class*="euiButton"] {
  border-radius: 0 5px 5px 0;
}

.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:nth-child(2) [class*="euiField"], .euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:nth-child(3) [class*="euiField"] {
  border-radius: 0;
}

.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:first-child .euiSelect, .euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:first-child .euiSuperSelectControl, .euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:first-child [class*="euiField"] {
  border-radius: 6px 0 0 6px;
}

.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:last-child .euiSelect, .euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:last-child .euiSuperSelectControl, .euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:last-child [class*="euiField"] {
  border-radius: 0 6px 6px 0;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed {
  background-color: #e9edf3;
  border-radius: 4px;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed.euiFormControlLayout--readOnly input {
  background-color: #fff;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__prepend:first-child {
  border-radius: 3px 0 0 3px;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__prepend:first-child [class*="euiButton"] {
  border-radius: 4px 0 0 4px;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__append:last-child, .euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__append:last-child [class*="euiButton"], .euiFormControlLayout--group.euiFormControlLayout--compressed .euiToolTipAnchor > .euiIcon {
  border-radius: 0 3px 3px 0;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiToolTipAnchor:first-child [class*="euiButton"] {
  border-radius: 3px 0 0 3px;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiToolTipAnchor:last-child .euiText, .euiFormControlLayout--group.euiFormControlLayout--compressed .euiToolTipAnchor:last-child [class*="euiButton"] {
  border-radius: 0 3px 3px 0;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper:nth-child(2) [class*="euiField"], .euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper:nth-child(3) [class*="euiField"] {
  border-radius: 0;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper:first-child .euiSelect, .euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper:first-child [class*="euiField"] {
  border-radius: 3px 0 0 3px;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper:last-child .euiSelect, .euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper:last-child [class*="euiField"] {
  border-radius: 0 3px 3px 0;
}

.euiFormControlLayoutDelimited {
  border-radius: 6px;
}

.euiFormControlLayoutDelimited.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:first-child {
  border-radius: 6px 0 0 6px;
}

.euiFormControlLayoutDelimited .euiFormControlLayout__childrenWrapper:only-child {
  border-radius: 6px;
  overflow: hidden;
}

.euiFormControlLayoutDelimited .euiFormControlLayout__prepend + .euiFormControlLayout__childrenWrapper:last-child {
  border-radius: 0 6px 6px 0;
}

.euiFormControlLayoutDelimited.euiFormControlLayout--compressed.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:first-child {
  border-radius: 4px 0 0 4px;
}

.euiFormControlLayoutDelimited.euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper:only-child {
  border-radius: 4px;
  overflow: hidden;
}

.euiFormControlLayoutDelimited.euiFormControlLayout--compressed .euiFormControlLayout__prepend + .euiFormControlLayout__childrenWrapper:last-child {
  border-radius: 0 4px 4px 0;
}

.euiRadio .euiRadio__input:focus + .euiRadio__circle, .euiRadio .euiRadio__input:focus:focus-visible + .euiRadio__circle {
  outline-offset: 2px;
  outline: 2px solid #0071c2;
}

.euiRadio .euiRadio__input:focus:not(:focus-visible) + .euiRadio__circle {
  outline: none;
}

.euiCheckbox .euiCheckbox__input:focus + .euiCheckbox__square, .euiCheckbox .euiCheckbox__input:focus:focus-visible + .euiCheckbox__square {
  outline-offset: 2px;
  outline: 2px solid #0071c2;
}

.euiCheckbox .euiCheckbox__input:focus:not(:focus-visible) + .euiCheckbox__square {
  outline: none;
}

.euiSwitch .euiSwitch__button:focus {
  outline-offset: 2px;
  outline: 2px solid;
}

.euiSwitch .euiSwitch__button:focus:focus-visible {
  outline-style: auto;
}

.euiSwitch .euiSwitch__button:focus .euiSwitch__track, .euiSwitch .euiSwitch__button:focus:not(:focus-visible) {
  outline: none;
}

.root {
  height: 100vh;
  padding-top: 48px;
  display: flex;
}

.euiToastHeader {
  align-items: center;
}

.euiOverlayMask[data-relative-to-header="below"] {
  top: 0;
}

.root .euiDataGridRowCell__actionButtonIcon {
  block-size: 12px;
  inline-size: 12px;
}

.signin-form, .signup-form, .password-form {
  width: 280px;
}
/*# sourceMappingURL=index.a6feccab.css.map */
